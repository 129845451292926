import { RenderComponent } from '@vue-storefront/core'
import { Logger } from '@vue-storefront/core'
import { Image, MappedImage } from '../types'

const extractNestedItem = (item) => (item.item ? item.item : item)
const extractNestedProps = (item) => (item.props ? item.props : item)

export const getImageObject = (image: Image): MappedImage => ({
  url: image.url,
  alt: image.title,
  metadata: image,
})

const filterProps = [
  'component',
  '_in_progress',
  '_version',
  'ACL',
  'publish_details',
  'tags',
  'created_at',
  'created_by',
  'updated_at',
  'updated_by',
  '_metadata',
]

export const extractComponents = (items: any[]): RenderComponent[] => {
  if (!items || !items.length) {
    Logger.error('No content items provided for render')
    return []
  }
  return items.map((item) => {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    const { $, ...itemComponent } = item
    const data = {
      ...(Object.values(itemComponent).length === 1 ? Object.values(itemComponent)[0] : item),
    }
    const componentName = data.component === 'Reference' ? 'RenderContent' : data.component

    return {
      componentName,
      props: ((data) => {
        filterProps.forEach((prop) => delete data[prop])
        if (data.image) {
          data.image = getImageObject(data.image)
        }
        if (data.images && Array.isArray(data.images)) {
          data.images = data.images.map(getImageObject)
        }
        if (data.items && Array.isArray(data.items)) {
          data.items = data.items.map(extractNestedItem)
        }
        if (data.props) {
          data = {
            ...data,
            ...extractNestedProps(data),
          }
          delete data.props
        }
        if (data.$) {
          data.live_preview = data.$
          delete data.$
        }
        if (data.component) {
          delete data.component
        }
        return data
      })(data),
    }
  })
}
